<template>
  <v-card>
    <v-card-text v-if="companyId || clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            :title="
              listType === 'open' ? 'Banka Online Bekleyen' : 'Banka İşlenen'
            "
            :icon="
              listType === 'open' ? 'mdi mdi-cash-clock' : 'mdi mdi-bank-check'
            "
            :show-delete="can('delete-bank-online') && listType === 'open'"
            @clear-filters="$refs.headSearch.reset"
            :edit-enabled="selectedItems.length === 1"
            :delete-enabled="selectedItems.length > 0"
            :search.sync="search.query"
            @reload="loadList"
            @click:edit="handleEditClick"
            @click:delete="handleBatchDeleteClick"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tarih Filtrele"
                :items="dateFilterList"
                v-model="createdAtFilter"
                @change="handleDateFilterChange"
                v-if="listType === 'closed'"
                dense
              />
            </template>
          </rs-table-title-bar>

          <v-row dense v-if="can('edit-bank-online') && listType === 'open'">
            <v-col>
              <rs-action
                v-if="singleItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleCollectingFormClick"
                label="Tahsilat Eşleştir"
                :loading="isLoading"
                tooltip="Sakin ve bağımsız bölüm seçerek tahsilat işleyin"
              >
                Tahsilat Eşleştir
                <!-- <v-icon small>mdi-connection</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsNegative"
                class="me-1 mb-1"
                @click.prevent="handleBillFormClick"
                :loading="isLoading"
                tooltip="Gider miktarını fatura olarak işleyin"
              >
                Fatura Eşleştir
                <!-- <v-icon small>mdi-receipt-text</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsNegative"
                class="me-1 mb-1"
                @click.prevent="handleAdvanceClick"
                :loading="isLoading"
                tooltip="Gider miktarını bir tedarikçiye avans olarak işleyin"
              >
                Avans Eşleştir
                <!-- <v-icon small>mdi-receipt-text</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsNegative"
                class="me-1 mb-1"
                @click.prevent="handleBankToSafeClick"
                :loading="isLoading"
                tooltip="Bankadan kasaya transfer oluşturun"
              >
                Para Çekme Eşleştir
                <!-- <v-icon small>mdi-bank-transfer-out</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleBankToSafeClick"
                :loading="isLoading"
                tooltip="Kasadan bankaya transfer oluşturun"
              >
                Para Yatırma Eşleştir
                <!-- <v-icon small>mdi-bank-transfer-in</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected"
                class="me-1 mb-1"
                @click.prevent="handleBankToBankClick"
                :loading="isLoading"
                tooltip="Bankadan bankaya transfer oluşturun"
              >
                Banka Transfer
                <!-- <v-icon small>mdi-bank-transfer</v-icon> -->
              </rs-action>

              <rs-action
                v-if="anItemSelected"
                class="me-1 mb-1"
                @click.prevent="handleStatusUpdateClick(11)"
                :loading="isLoading"
                tooltip="Banka hareketini doğrudan işlenenlere gönderir. Gelir veya gider olarak işlenmez."
              >
                Elle Muhasebe
                <!-- <v-icon small>mdi-gesture-tap</v-icon> -->
              </rs-action>

              <rs-action
                v-if="anItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleStatusUpdateClick(14)"
                :loading="isLoading"
                tooltip="Paranın nereden geldiğini bilmiyorsanız buraya tıklayarak 'diğer gelir' oluşturabilirsiniz"
              >
                Yatıran Belirsiz
                <!-- <v-icon small>mdi-account-question</v-icon> -->
              </rs-action>

              <rs-action
                v-if="anItemSelected"
                class="me-1 mb-1"
                @click.prevent="handleRerunClick"
                :loading="isLoading"
                :disabled="selectedItems.length !== 1"
                tooltip="Banka entegrasyon kurallarına göre otomatik eşleştirmeyi tekrar çalıştırır"
              >
                Yeniden Çalıştır
                <!-- <v-icon small>mdi-motion-play-outline</v-icon> -->
              </rs-action>

              <rs-action
                v-if="anItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleStatusUpdateClick(13)"
                :loading="isLoading"
                tooltip="Faiz geliri türünde bir diğer gelir oluşturur"
              >
                Faiz Geliri
                <!-- <v-icon small>mdi-bank-plus</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleOtherIncomeClick"
                :loading="isLoading"
                tooltip="TYA sakinlerinden olmayan gelirlerinizi diğer gelir olarak işleyebilirsiniz"
              >
                Diğer Gelir
                <!-- <v-icon small>mdi-connection</v-icon> -->
              </rs-action>

              <rs-action
                v-if="singleItemSelected && allItemsPositive"
                class="me-1 mb-1"
                @click.prevent="handleEnforcementTransactionClick"
                :loading="isLoading"
                tooltip="Açık icra dosyalarından birini seçerek tahsilat işleyebilirsiniz"
              >
                İcra Tahsilatı
                <!-- <v-icon small>mdi-alert-circle-check-outline</v-icon> -->
              </rs-action>

              <rs-action
                class="me-1 mb-1"
                @click.prevent="() => false"
                label="İşlevsiz tuş"
                style="visibility: hidden"
                >&nbsp;</rs-action
              >
            </v-col>
          </v-row>
          <v-row
            dense
            v-else-if="can('edit-bank-online') && listType === 'closed'"
          >
            <v-col>
              <v-btn
                v-if="anItemSelected"
                class="me-1 mb-1"
                color="pink"
                outlined
                dark
                small
                @click.prevent="handleCancelClick"
                v-on="on"
                v-bind="attrs"
                title="Muhasebeleştirmeyi İptal Et"
                :loading="isLoading"
              >
                Muhasebeleştirme İptal
              </v-btn>

              <rs-action
                class="me-1 mb-1"
                @click.prevent="() => false"
                label="İşlevsiz tuş"
                style="visibility: hidden"
                >&nbsp;</rs-action
              >
            </v-col>
          </v-row>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            :to="{ name: 'incomes.bank.show', params: { id: item.id } }"
            title="Detay Göster"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            v-if="item.house_id"
            :to="{
              name: 'incomes.houses.show',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item, value }">
          <router-link
            v-if="item.resident_id"
            :to="{
              name: 'definitions.residents.edit',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_account="{ item, value }">
          <router-link
            title="Banka Hesabı Tanımına Git"
            v-if="item.bank_account_id"
            :to="{
              name: 'definitions.bank-accounts.edit',
              params: { id: item.bank_account_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item, value }">
          <router-link
            title="Tedarikçi Tanımına Git"
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.edit',
              params: { id: item.provider_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.activity_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price colored :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.balance="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_manual_import="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ item, value }">
          <router-link
            :to="{ name: 'incomes.bank.show', params: { id: item.id } }"
            title="Detay Göster"
          >
            <rs-table-cell-string :limit="20" :value="value" />
          </router-link>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.transaction_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Listeyi görmek için üst menüden bir yönetim şirketi veya TYA seçin.
    </v-card-text>

    <BankToBankForm ref="bankToBankForm" @saved="loadList" />
    <BankToSafeForm ref="bankToSafeForm" @saved="loadList" />
    <TransactionToAdvanceForm
      ref="transactionToAdvanceForm"
      @saved="loadList"
    />
    <TransactionToCollecting
      ref="transactionToCollectingForm"
      @saved="loadList"
    />
    <TransactionToEnforcementTransactionForm
      ref="transactionToEnforcementTransactionForm"
      @saved="loadList"
    />
    <TransactionToInvoice ref="transactionToInvoiceForm" @saved="loadList" />

    <rs-confirm
      ref="confirmCancel"
      :loading="isLoading"
      @confirmed="handleCancelConfirmed"
      @cancelled="$refs.confirmCancel.hide()"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions, isPage } from "@/view/mixins";
import {
  BankToBankForm,
  BankToSafeForm,
  TransactionToAdvanceForm,
  TransactionToCollecting,
  TransactionToEnforcementTransactionForm,
  TransactionToInvoice,
} from "@/view/pages/income/forms";

export default {
  name: "BankActivityList",
  mixins: [filtersToURL, hasExport, hasPermissions, isPage],
  props: {
    listType: {
      type: String,
      required: false,
      default: "open",
    },
  },
  components: {
    BankToBankForm,
    BankToSafeForm,
    TransactionToAdvanceForm,
    TransactionToCollecting,
    TransactionToEnforcementTransactionForm,
    TransactionToInvoice,
  },
  computed: {
    ...mapGetters([
      "clusterId",
      "companyId",
      "company",
      "bankActivityStatusList",
    ]),
    statusListOptions() {
      if (this.listType === "open") {
        return this.bankActivityStatusList.filter((item) => {
          return item.id < 10;
        });
      } else if (this.listType === "closed") {
        return this.bankActivityStatusList.filter((item) => {
          return item.id >= 10;
        });
      }

      return this.bankActivityStatusList;
    },
    singleItemSelected() {
      return this.selectedItems.length === 1;
    },
    anItemSelected() {
      return this.selectedItems.length > 0;
    },
    allItemsNegative() {
      for (const index in this.selectedItems) {
        if (this.selectedItems[index].amount > 0) {
          return false;
        }
      }

      return true;
    },
    allItemsPositive() {
      for (const index in this.selectedItems) {
        if (this.selectedItems[index].amount < 0) {
          return false;
        }
      }

      return true;
    },
  },
  watch: {
    "$route.path"() {
      this.$refs.headSearch.reset();
    },
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    companyId() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
    listType() {
      this.loadList();
    },
  },
  data() {
    return {
      pageMeta: {
        title: "Banka Online İşlemler Liste",
      },
      search: {
        created_at: {
          min: undefined,
          max: undefined,
        },
      },
      dateFilterList: [
        {
          id: 0,
          name: "Tümü",
        },
        {
          id: 1,
          name: "Son 30 gün",
        },
        {
          id: 2,
          name: "Son 1 yıl",
        },
      ],
      createdAtFilter: 0,
      options: {
        sortBy: ["activity_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "TYA",
          value: "cluster",
          searchable: "cluster",
          multiple: true,
          hide: () => this.clusterId,
        },
        {
          text: "Banka",
          value: "bank",
          searchable: "text",
          width: "130px",
          align: "center",
        },
        {
          text: "Hesap Adı",
          value: "bank_account",
          searchable: "text",
        },
        {
          text: "Tarih",
          value: "activity_on",
          searchable: "date",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Banka Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: "Muhasebe",
          value: "status",
          searchable: "multiselect",
          options: () => this.statusListOptions,
        },
        {
          text: "Sakin",
          value: "resident",
          searchable: "text",
          width: "155px",
          align: "center",
        },
        {
          text: "BB",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Tedarikçi",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Muhasebeleşen",
          value: "transaction_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Makbuz No",
          value: "receipt_no",
          searchable: "text",
        },
        {
          text: "IBAN",
          value: "iban",
          searchable: "text",
        },
        {
          text: "TCKN",
          value: "identity_number",
          searchable: "text",
        },
        {
          text: "VKN",
          value: "tax_number",
          searchable: "text",
        },
        {
          text: "XLSX",
          value: "is_manual_import",
          searchable: "yesNo",
        },
      ],
      titleBarAttrs: {
        exportUrl: "income/bank-activities",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);

    if (this.$route.query.id && this.$route.query.action) {
      setTimeout(() => {
        if (this.$route.query.action === "collecting") {
          this.$api
            .get("income/bank-activities", this.$route.query.id)
            .then((response) => {
              this.handleCollectingFormClick(response.data.data);
            })
            .catch((error) => {
              this.handleError(error);
            });
        }
      }, 500);
    }
  },
  methods: {
    ...mapActions(["updateBankActivityCount"]),
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.list_type = this.listType;

      if (this.clusterId) {
        params.cluster = [this.clusterId];
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      return params;
    },
    loadList() {
      if (this.isLoading || (!this.companyId && !this.clusterId)) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];
      this.selectedItems = [];

      const params = this.getParams();

      this.$api
        .query("income/bank-activities", {
          params,
        })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;

            // if (this.listType === "open") {
            //   this.updateBankActivityCount(this.total);
            // }
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleBankToSafeClick() {
      const item = this.selectedItems[0];
      this.$refs.bankToSafeForm.show(item);
    },
    handleBankToBankClick() {
      const item = this.selectedItems[0];
      this.$refs.bankToBankForm.show(item);
    },
    handleBillFormClick() {
      const item = this.selectedItems[0];
      this.$refs.transactionToInvoiceForm.show(item);
    },
    handleAdvanceClick() {
      const item = this.selectedItems[0];
      this.$refs.transactionToAdvanceForm.show(item);
    },
    handleCollectingFormClick(item) {
      this.$refs.transactionToCollectingForm.show(
        item.id ? item : this.selectedItems[0]
      );
    },
    handleEnforcementTransactionClick(item) {
      this.$refs.transactionToEnforcementTransactionForm.show(
        item.id ? item : this.selectedItems[0]
      );
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "incomes.bank.show",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleStatusUpdateClick(statusId) {
      if (this.isLoading) {
        return false;
      }

      if (!this.selectedItems.length) {
        this.$toast.warning("En az bir satır seçmelisiniz");
        return false;
      }

      this.isLoading = true;

      const params = {
        status_id: statusId,
        ids: this.selectedItems.map((item) => item.id),
      };

      this.$api
        .post("income/bank-activities", params)
        .then(() => {
          setTimeout(() => this.loadList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCancelClick() {
      this.$refs.confirmCancel.show(
        `${this.selectedItems.length} tane işlemi iptal etmek istediğinizden emin misiniz?`
      );
    },
    handleCancelConfirmed() {
      if (!this.selectedItems.length || this.isLoading) {
        return false;
      }

      const ids = this.selectedItems.map((item) => item.id);
      this.isLoading = true;

      return this.$api
        .post(`income/bank-activities/cancel-transactions`, { ids: ids })
        .then(() => {
          this.$toast.success("İptal Edildi");
          this.$refs.confirmCancel.hide();
          window.setTimeout(() => this.loadList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDateFilterChange() {
      if (this.createdAtFilter === 0 || this.createdAtFilter === null) {
        this.search.created_at.min = undefined;
        this.search.created_at.max = undefined;
      } else if (this.createdAtFilter === 1) {
        const t = new Date();
        this.search.created_at = {
          min: new Date(t.getFullYear(), t.getMonth() - 1, t.getDate()),
          max: undefined,
        };
      } else if (this.createdAtFilter === 2) {
        const t = new Date();
        this.search.created_at = {
          min: new Date(t.getFullYear() - 1, t.getMonth(), t.getDate()),
          max: undefined,
        };
      }
    },
    handleBatchDeleteClick() {
      if (this.isLoading || !this.selectedItems.length) {
        return false;
      }

      for (const index in this.selectedItems) {
        if (!this.selectedItems[index].is_manual_import) {
          return this.$toast.error(
            "Sadece manuel olarak içeri alınan kayıtları silebilirsiniz."
          );
        }
      }

      const promises = [];
      this.isLoading = true;

      for (const index in this.selectedItems) {
        promises.push(this.delete(this.selectedItems[index]));
      }

      Promise.all(promises)
        .then(() => {
          this.$toast.success("Seçilen kayıtlar silindi.");
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;

          this.loadList();
        });
    },
    delete(item) {
      return this.$api.delete(`income/bank-activities/${item.id}`);
    },
    handleOtherIncomeClick() {
      const item = this.selectedItems[0];

      this.$router.push({
        name: "incomes.other.create",
        query: {
          bank_activity_id: item.id,
        },
      });
    },
    handleRerunClick() {
      if (this.isLoading || this.selectedItems.length !== 1) {
        return false;
      }

      const id = this.selectedItems[0].id;

      this.isLoading = true;

      this.$api
        .post(`income/bank-activities/${id}/match`)
        .then(() => {
          this.$toast.success("Otomatik eşleştirme tekrar çalıştırıldı.");

          setTimeout(() => this.loadList(), 100);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
